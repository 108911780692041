<template>
  <!-- 分润管理 -->
  <div>
    <!-- 搜索区域 -->
      <h4 class="giter">分润管理</h4>
    <div class="form">
      <!-- 手机号查询 -->
      <div>
        <span>按手机号查询:</span>
        <el-input placeholder="请输入内容" v-model="form.phone" clearable style="width: 200px; display: block"> </el-input>
      </div>
      <el-button type="primary" icon="el-icon-search" style="height: 40px; margin-top: 40px">搜索</el-button>
    </div>
    <!-- 表单区域 -->
    <div style="margin-top: 30px">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="buyUserId" label="序号" width="" align="center"> </el-table-column>
        <el-table-column prop="buyTel" label="手机号" width="" align="center"> </el-table-column>
        <el-table-column prop="buyName" label="交易金额" width="" align="center"> </el-table-column>
        <el-table-column prop="tokenName" label="分润金额" width="" align="center"> </el-table-column>
        <!-- <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <!-- 分页选项 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage4"
        :page-sizes="[10, 15, 20, 50, 100]"
        :page-size="100"
        layout="total, sizes, prev, pager, next, jumper"
        :total="100"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: '',
        name: ''
      },
      tableData: [{
          buyUserId:"1",
          buyTel:"1662255887",
          buyName:"20000",
          tokenName:"68852258",
      },
    ],
      currentPage4: 4
    }
  },
  created() {
  },
  methods: {
    
    on() {
      console.log(123)
    },
    // 重置
    restForm() {
      this.form = {
        phone: '',
        name: ''
      }
    },
    // 分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.giter{
    color: #409eff;
}
.form {
    margin-left: 40px;
  display: flex;
  div {
    margin-right: 20px;
    span {
      font-weight: bold;
      display: inline-block;
      margin-bottom: 20px;
    }
  }
}
.pagination {
  padding: 60px 0 0 110px;
}
</style>
